import React from "react"

import * as S from "./styled"
import Button from "../../newUikit/Button"
import { Container, Description, JoinButton } from "../CommunitySection"
import { Caption, Heading } from "../uikit/Typographic"

const ApiKeyForm = ({ content, openModal }) => {
  return (
    <>
      <S.Container>
        <div className="inner">
          <Container>
            <Caption>{content.label}</Caption>
            <Heading>{content.title}</Heading>
            <Description>{content.description}</Description>
            <JoinButton
              as="a"
              color="cyan"
              href={content.signUpLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {content.signUp}
            </JoinButton>
          </Container>
          <S.AdditionalText>
            <span className="text">{content.forSales}</span>
            <Button size="sm" inverse onClick={openModal}>
              {content.salesBtn}
            </Button>
          </S.AdditionalText>
        </div>
      </S.Container>
    </>
  )
}

export default ApiKeyForm
