import React, { useState } from "react";
import { FAQSection, FAQTitle, FAQItem, FAQAnswer, FAQQuestion } from "./styled";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is Dasha AI?",
      answer: "Dasha AI is a production-ready conversational AI platform that enables businesses to create ultra-realistic voice and text AI agents. Our platform uses advanced language models to deliver human-like interactions that can handle real-world tasks, enhance sales, improve customer service, and automate processes at scale."
    },
    {
      question: "How is Dasha different from competitors?",
      answer: "Dasha is the most mature and production-ready solution in the market, with several key advantages: 1) True scalability - handling hundreds of concurrent calls while maintaining performance, 2) Production stability - including automatic failover between providers, real-time monitoring, and comprehensive testing frameworks, 3) Advanced telephony features like warm transfers and smart queuing that others don't offer, 4) Support for all LLMs with the unique ability to use multiple LLMs in the same call, 5) Multi-language support with 30+ languages and mid-call language switching, and 6) Bring-your-own VoIP provider flexibility. While newer platforms focus on demos and prototypes, Dasha delivers enterprise-grade reliability for real-world, high-volume operations."
    },
    {
      question: "Does Dasha AI have any kind of no-code voice AI agents builder?",
      answer: "No, Dasha AI does not offer a no-code builder. This is a deliberate choice because building advanced, real-world voice AI agents that can handle complex scenarios requires proper programming and customization that simply cannot be achieved through a no-code interface. Dasha AI is specifically designed for developers who need to create sophisticated, production-ready voice applications. For businesses without in-house developers, we can connect you with certified Dasha developer experts from our network - just reach out to support@dasha.ai."
    },
    {
      question: "Do I need to have developers on my team to use Dasha?",
      answer: "Yes, Dasha is a powerful and highly customizable platform that requires development expertise to fully leverage its capabilities. If you don't have a developer on your team, we have two solutions: you can hire a developer through platforms like UpWork, or contact us at support@dasha.ai and we'll connect you with certified Dasha developer experts from our network who can help implement your solution."
    },
    {
      question: "What languages does Dasha support?",
      answer: "Dasha supports over 30 languages and can even switch between languages during a call. This makes it ideal for businesses operating in multiple regions or serving a global customer base. Our platform ensures natural, fluent conversation in each supported language."
    },
    {
      question: "Can I integrate Dasha with my existing phone system?",
      answer: "Yes, Dasha offers flexible telephony integration. You can connect to your own SIP/VoIP providers, use multiple providers simultaneously, and even bring your existing phone numbers. This allows you to maintain your current infrastructure while adding AI capabilities."
    },
    {
      question: "What Language Models (LLMs) does Dasha AI support?",
      answer: "Dasha supports all available Language Models, and uniquely allows you to use multiple LLMs on the same call! This flexibility ensures you can always use the best model for each specific task and provides redundancy for uninterrupted service."
    },
    {
      question: "How does Dasha ensure reliable performance at scale?",
      answer: "Dasha's infrastructure is built for high-volume operations with automatic failover mechanisms, load balancing across providers, and real-time monitoring. We also provide comprehensive testing tools, including end-to-end and unit testing, to ensure reliable performance even under heavy loads."
    },
    {
      question: "Can I white-label Dasha for my own business?",
      answer: "Yes! Dasha is designed as a white-label platform, allowing you to rebrand our solution under your own company name. This means you can offer AI voice capabilities to your customers while maintaining your brand identity. We provide full white-labeling support and never compete with our partners in the market."
    },
    {
      question: "Can I customize the AI agent's voice and behavior?",
      answer: "Yes, Dasha offers unlimited custom voice clones and extensive customization options through DashaScript, our proprietary programming language. You can tailor the agent's voice, personality, responses, and behavior to match your brand and specific use cases."
    },
    {
      question: "How does Dasha handle data integration?",
      answer: "Dasha provides robust API integration capabilities that allow your AI agents to seamlessly connect with your CRM, databases, and other business systems. This enables real-time data access and updates during conversations, ensuring accurate and personalized interactions."
    },
    {
      question: "What kind of support does Dasha provide?",
      answer: "Dasha offers dedicated support throughout your journey, from initial setup to ongoing optimization. As a white-label platform, we partner with you for success while allowing you to present the solution as your own brand."
    },
    {
      question: "How can I measure the performance of my AI agents?",
      answer: "Dasha provides comprehensive analytics and monitoring tools, including event tracking, funnel building, and A/B testing capabilities. You can track custom events, analyze call outcomes, and optimize your agents based on real performance data."
    },
    {
      question: "Can Dasha handle both inbound and outbound calls?",
      answer: "Yes, Dasha is optimized for both inbound customer service and outbound sales/marketing campaigns. The platform includes features like smart call scheduling, voicemail detection, and warm live transfers to ensure effective handling of both types of calls."
    },
    {
      question: "What about security and reliability?",
      answer: "Dasha prioritizes security and reliability through resilient infrastructure, automatic failover mechanisms, and real-time monitoring. Our platform ensures uninterrupted service even if third-party providers experience issues, and includes comprehensive testing tools to maintain consistent performance. You can monitor our platform's real-time operational status at https://status.dasha.ai/."
    },
    {
      question: "How quickly can I get started with Dasha?",
      answer: "The fastest way to get started is to schedule a call with our team by clicking 'Contact Sales.' Our experts will guide you through the platform and help create a solution tailored to your needs. Alternatively, you can explore our capabilities immediately through our Playground at playground.dasha.ai."
    }
  ];

  const toggleFAQ = (index, event) => {
    if (event) {
      event.preventDefault();
    }
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <FAQSection>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      {faqs.map((faq, index) => (
        <FAQItem key={index}>
          <FAQQuestion onClick={(e) => toggleFAQ(index, e)}>
            {faq.question}
          </FAQQuestion>
          <FAQAnswer isActive={activeIndex === index}>
            {faq.answer}
          </FAQAnswer>
        </FAQItem>
      ))}
    </FAQSection>
  );
};

export default FAQ; 