import React from "react"
import { Indent } from "src/uikit"
import * as S from "./styled"

const IntegrationGrid = ({ theme }) => {
  return (
    <S.Container theme={theme}>
      <Indent lg={80} md={80} sm={64} />
      <div className="inner">
        <S.Title theme={theme}>Seamlessly Connect With Your Entire Tech Stack</S.Title>
        <S.Subtitle theme={theme}>Trusted By Teams Using:</S.Subtitle>
        
        <S.IntegrationsImage 
          theme={theme}
          src="/integrations-grid.png"
          alt="Integration partners including Salesforce, Zoho, Pipedrive, and other popular business tools"
        />

        <S.ConnectText theme={theme}>
          Integrate Through Our API and SDK or Connect Instantly With 1000+ Apps via {" "}
          <S.ZapierLink theme={theme} href="https://zapier.com" target="_blank" rel="noopener noreferrer">
            zapier
          </S.ZapierLink>{" "}
        </S.ConnectText>
      </div>
    </S.Container>
  )
}

export default IntegrationGrid 