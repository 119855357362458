import styled from "styled-components";

export const FAQSection = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  background-color: ${({ theme }) => theme.bg};
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const FAQTitle = styled.h2`
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 32px;
  color: ${({ theme }) => theme.text};
`;

export const FAQItem = styled.div`
  margin-bottom: 0px;
  padding-bottom: 16px;

  &:last-child {
    border-bottom: none;
  }
`;

export const FAQQuestion = styled.div`
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ theme }) => theme.text};
  padding: 16px 0;
  text-align: left;
  border-bottom: 1px solid #ddd;

  &:hover {
    color: ${({ theme }) => theme.link};
    border-bottom: 1px solid ${({ theme }) => theme.link};
  }
`;

export const FAQAnswer = styled.div`
  font-size: 15px;
  line-height: 1.6;
  color: ${({ theme }) => theme.text};
  margin-top: 12px;
  padding: 12px;
  background-color: ${({ theme }) => theme.answerBg || theme.bg || '#f9f9f9'};
  border-radius: 4px;
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: ${({ isActive }) => (isActive ? "1000px" : "0")};
  opacity: ${({ isActive }) => (isActive ? "1" : "0")};
  overflow: hidden;
`; 