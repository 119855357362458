import styled from "styled-components"
import { media } from "src/uikit"

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme === 'dark' ? '#161729' : '#f6f6fb'};
  padding: 0 16px;
  margin-bottom: 80px;

  .inner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
  }

  @media ${media.mobile} {
    padding: 0 16px;
    margin-bottom: 64px;
  }
`

export const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: ${({ theme }) => theme === 'dark' ? '#fff' : '#001553'};
  margin: 0 0 16px;

  @media ${media.mobile} {
    font-size: 26px;
    line-height: 32px;
  }
`

export const Subtitle = styled.p`
  font-size: 17px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${({ theme }) => theme === 'dark' ? '#fff' : '#001553'};
  margin: 0 0 48px;

  @media ${media.mobile} {
    font-size: 15px;
    line-height: 28px;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 32px;
  margin-bottom: 48px;
  width: 100%;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${media.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
  }
`

export const Logo = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
  transition: 0.3s;

  @media ${media.mobile} {
    width: 40px;
    height: 40px;
  }
`

export const Name = styled.span`
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: #142470;
`

export const ConnectText = styled.p`
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme === 'dark' ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 21, 83, 0.5)'};
  text-transform: uppercase;
  margin: 0;
  padding-bottom: 80px;

  @media ${media.mobile} {
    padding-bottom: 64px;
  }
`

export const ZapierLink = styled.a`
  color: ${({ theme }) => theme === 'dark' ? '#8785FF' : '#5855F4'};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export const IntegrationsImage = styled.img`
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin-bottom: 48px;
  filter: ${({ theme }) => theme === 'dark' ? 'drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.2))' : 'none'};
  
  @media ${media.mobile} {
    margin-bottom: 32px;
  }
` 