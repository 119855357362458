import styled from "styled-components"

import { Caption } from "../uikit/Typographic"

export const Container = styled.section`
  margin-bottom: 144px;
  display: flex;
  justify-content: center;
  padding: 0 12px;

  ${Caption} {
    margin-bottom: 16px;
  }

  @media (max-width: 1199px) {
    padding-left: 32px;
    padding-right: 32px;

    margin-bottom: 56px;
  }

  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (max-width: 600px) {
    margin-bottom: 56px;
    padding-left: 12px;
    padding-right: 12px;

    ${Caption} {
      margin-bottom: 8px;
    }
  }

  .inner {
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      align-items: flex-start;
    }
  }

  .heading {
    max-width: 1200px;
    margin: 0 auto 40px;

    @media (max-width: 1199px) {
      margin-bottom: 16px;
    }

    @media (max-width: 600px) {
      margin: 0 0 16px;
    }

    h2,
    h4 {
      text-align: center;
    }
  }

  .box {
    position: relative;
    margin-bottom: 84px;
    width: 100%;

    @media (max-width: 1199px) {
      margin-bottom: 60px;
    }

    @media (max-width: 600px) {
      margin-bottom: 48px;
    }
  }
`

export const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    min-width: 145px;
    width: fit-content;
    height: 40px;
    padding: 0 8px;
  }

  .text {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${props => props.theme.forSalesText};
    margin-right: 24px;
  }

  button:not(:disabled):active,
  button:not(:disabled):hover,
  button {
    ${props =>
      props.theme.buttonInverseColor &&
      "color: " + props.theme.buttonInverseColor}
  }

  @media (max-width: 599px) {
    margin: 0 auto;
    flex-direction: column;
    align-items: center;

    button {
      width: 288px;
    }

    .text {
      margin-right: 0;
      margin-bottom: 16px;
    }

    button {
      width: 288px;
      height: 48px;
    }
  }
`

export const Warning = styled.p`
  max-width: 993px;
  display: flex;
  justify-content: center;
  padding: 0 32px;
  font-size: 11px !important;
  line-height: 16px !important;
  text-align: left !important;

  color: ${props => props.theme.text};

  a {
    color: #8785ff;
    font-weight: 600;
    text-decoration: none;
    padding: 0 2px;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 756px) {
    margin-top: 16px;
  }

  @media (max-width: 1199px) {
    padding: 0;
  }
`
