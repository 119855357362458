import React from "react"

import { Modal, ModalBody } from "src/uikit/Modal"
import { Title, FormContainer } from "src/uikit/Modal/styled"
import CalendlyWidget from "../../components/CalendlyWidget"


const ContactModal = ({ isOpen = true, content, onClose }) => {

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalBody>
          <Title>{content.title}</Title>
          <FormContainer>
            <CalendlyWidget/>
          </FormContainer>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ContactModal
